import * as React from 'react';
import ChannelDetailsModalContent from './ChannelDetailsModalContent';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ChannelModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
        <ChannelDetailsModalContent
        title={props.title}
        description={props.description}
        channelId={props.channelId}
        handleOpen={handleOpen}
        handleClose={handleClose}
        style={style}
        open={open}
        />
    </div>
  );
}

export default ChannelModal;
