import { doc, getDoc, query, where, collection, getDocs } from 'firebase/firestore'
import React, { Children, useContext, useEffect, useState } from 'react'
import { auth } from '../firebase'
import { db } from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const q = query(collection(db, "users"), where("email", "==", "tesrer3@mailinator.com"));
    // async () => {
    //     const querySnapshot = await getDocs(q);
    //     querySnapshot.forEach((doc) => {
    //         console.log(doc.name, "=>", doc.data());
    //     })
    // }

    // useEffect(() => {
    //     db.collection("users")
    //     .where("email", "==", "tesrer3@mailinator.com")
    //     .get()
    //     .then(resp => {
    //         console.log("user data", resp.docs)
    //     }) 
    // }, [])
        
    

    
    

    function signup(email, password, firstName, lastName) {
        auth.createUserWithEmailAndPassword(email, password).then(cred => {
            // TODO pull uid to add to firestore
            const addUser = db.collection('users').add({firstName: firstName, lastName: lastName, email: email});
            return addUser;
        })
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email);
    }

    function updatePassword(password) { 
        return currentUser.updatePassword(password);
    }

    function updateName(name) {
        return currentUser.updateProfile({
            displayName: name
        })
    }

    function updateCompany(company) {
        // return db.collection('users').doc().update({company: company})
        return (
            db.collection("users")
            .where("email", "==", currentUser.email || "uid", "==", currentUser.uid)
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(document) {
                    document.ref.update({company: company, uid: currentUser.uid});
                })
            })
        )
    }

    function updatePhone(phone) {
        // return db.collection('users').doc().update({company: company})
        return (
            db.collection("users")
            .where("email", "==", currentUser.email || "uid", "==", currentUser.uid)
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(document) {
                    document.ref.update({phone: phone});
                })
            })
        )
    }

    function updateUser(uid, firstName, lastName, company, email) {
        // return db.collection('users').doc(doc.id).update({uid: uid, firstName: firstName, lastName: lastName, company})
        return (
            db.collection("users")
            .where("email", "==", email)
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(document) {
                    document.ref.update({uid: uid, firstName: firstName, lastName: lastName, company: company});
                })
            })
        )
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setLoading(false)
            setCurrentUser(user)
        })

        return unsubscribe;
    }, [])
    
    const value = {
        currentUser,
        signup,
        login,
        logout,
        useAuth,
        resetPassword,
        updateEmail,
        updatePassword,
        updateName,
        updateUser,
        updateCompany,
        updatePhone
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
