import React, { useState, useEffect, useContext } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Grid, Popover, Skeleton, Typography } from '@mui/material';
import './banners.scss';
import { ThemeContext } from '../App';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//     },
//     paper: {
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//     },
//     inline: {
//         display: 'inline',
//     },
//     nested: {
//         paddingLeft: theme.spacing(4),
//     },
// }));

function Banners(props) {
    // console.log("banners", props.currentNetwork[0].banners_api);
    
    const { loading = false } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const bannersAPI = props.bannersAPI;
    const [bannerList, setBannerList] = useState([{
        click_url: '',
        image: ''
    }])
    
    const [error, setError] = useState(null);
    const [baseUrl, setBaseUrl] = useState(localStorage.getItem("baseUrl"));
    
    const theme = useContext(ThemeContext);

    useEffect(() => {
        setBaseUrl(localStorage.getItem("baseUrl"));
        // console.log("baseUrl on dash", baseUrl);
    },[baseUrl]);

    useEffect(() => {
        if(bannersAPI) {
            fetch(bannersAPI)
            .then(result => result.json())
            .then((result) => {
    
                // console.log('response data?', result);
                setBannerList(result);
                setIsLoading(false);
                setIsLoaded(true);
                // setTheme(props.theme);
                // return () => ac.abort();
            })
            .catch(ex => console.error(ex));
        }
    },[bannersAPI])

    if (error) {
        return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
        return <div><Skeleton variant="rect" /></div>;
    } else {
        return (

            <>
                {isLoading && <div>Loading.... </div>}
                <CssBaseline/>
                {bannerList &&<div style={{padding: '0', borderBottom: `2px solid ${theme[0].dashboard_border_color}`, boxShadow: '0 5px 8px #000'}}>
                    <Grid className="spliderGridContainer" item style={{width: '100%'}}>
                        
                        <Grid className="splideGrid" item style={{margin: '0'}}>
                            
                                <Splide
                                options={ {
                                    type: 'loop',
                                    autoplay: true,
                                    interval: 5000,
                                    resetProgress: false,
                                    speed: 1000,
                                    perPage: 1,
                                    perMove: 1,
                                    arrows: true,
                                    width: '100%',
                                } }
                                >
                                    {bannerList.map((banner, i) => (
                                    <SplideSlide className="splideItem" key={i} style={{width: '100%'}}>
                                        <a href={banner.click_url} target="_blank">
                                            {banner.image ? (
                                                <img src={baseUrl+banner.image} alt={banner.name} style={{width: '100%'}}/>
                                                ) : (
                                                <Skeleton variant="rect" width='100%' />
                                            )}
                                        </a>
                                    </SplideSlide>
                                ))} 
                            </Splide>
                        </Grid>
                    </Grid>
                </div>}

            </>
        );
    }
}

export default Banners;