import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ThemeContext } from '../App';

function TrackDetailsModalContent(props) {
    const theme = React.useContext(ThemeContext);
    // const AddToFavorites = function(url, title) {
    //     var ua = navigator.userAgent.toLowerCase();
    //     if (ua.indexOf("msie 8") > -1) {
    //         external.AddToFavoritesBar(url, title, '');
    //     } else {
    //        try {
    //           window.external.addFavorite(url, title);
    //        } catch (e) {
    //           try {
    //               window.sidebar.addPanel(title, url, '');
    //           } catch (e) {
    //               alert("Please try shortcut Ctrl+D to add to favorite");
    //           }
    //       }
    //     }
    //     return false;
    //  }

    return (
        <React.Fragment>
            {props.title && <Button onClick={props.handleOpen} style={{color: theme[0].media_player_font_color}}>View Details  <InfoIcon /></Button>}
            {/* <a href="#" onClick={AddToFavorites('https://shelcaster.tv','Test Favorite')}>Add to Favorites</a> */}
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{backgroundColor: 'rgba(0,0,0,.6)', border: '2px solid #fff'}}
            >
                <Box sx={props.style} style={{backgroundColor: 'rgba(0,0,0,.6)', border: '1px solid #fff'}}>
                    <Typography style={{background: 'none', color: '#fff'}} id="modal-modal-title" variant="h6" component="h2">
                        {props.title}
                    </Typography>
                    <Typography style={{background: 'none', color: '#fff'}} id="modal-modal-description" body2 component="html" sx={{ mt: 2 }}>
                        {props.author}
                        <Typography style={{background: 'none', color: '#fff'}} body2 component="body" dangerouslySetInnerHTML={{__html: props.description}}/>
                    </Typography>
                </Box>
            </Modal>
        </React.Fragment>
    )
}
export default TrackDetailsModalContent