// import { React, useEffect, useState } from 'react';
import * as React from 'react';
import '../../styles/global.scss';
import {useEffect, useState, useRef } from 'react';
// import { makeStyles } from '@mui/styles';
// import Typography from '@material-ui/core/Typography';
//List
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import Divider from '@material-ui/core/Divider';
// import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@mui/icons-material/List';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
// import Avatar from '@material-ui/core/Avatar';
import MediaPlayer from './MediaPlayer';
import './playlist.scss';
import styles from './playlist.scss';
import SignalCellularNullIcon from '@mui/icons-material/SignalCellularNull';
import { Container, Grid, ListItemIcon, ListItemSecondaryAction, Popover, Skeleton, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import { ThemeContext } from '../App';
import { Button } from 'react-bootstrap';
import SoundBars from './SoundBars';

function Playlist(props) {
    const currentTrackRef = useRef();
    
    const [activeTrack, setActiveTrack] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [playProgram, setPlayProgram] = useState(null);
    const [broadcast_type, setBroadcastType] = useState(null);
    const [title, setTitle] = useState(null);
    const [author, setAuthor] = useState(null);
    const [description, setDescription] = useState(null);
    const [track, setTrack] = useState(0);
    const [programImage, setProgramImage] = useState(props.channelImage);
    const baseUrl = props.baseUrl;
    const channelBaseUrl = props.channelBaseUrl;
    const [programsAPI, setProgramsAPI] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [channelImage, setChannelImage] = useState(props.channelImage);
    const [open, setOpen] = React.useState(false);
    const theme = React.useContext(ThemeContext);
    const programs = props.programs;

    function highlightTrack(track) {
        return new Promise(resolve => {
            if(document.getElementById(track)){
                let element = document.getElementById(track);
                element.classList.add("activeTrack");
                element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            }
        })
    }

    async function clearTrackHighlight(index) {
        let highlightedTracks = document.querySelectorAll('.activeTrack');
        if(highlightedTracks.length > 0){
            highlightedTracks.forEach(highlightedTrack => {
                highlightedTrack.classList.remove('activeTrack');
            })
            await highlightTrack(index);
        } else {
            await highlightTrack(index);
        }
    }

    function handleSoundBars(playing) {
        if(playing) {
            return true
        } else {
            return false
        }
    }

    const setProgramUrl = (item, index) => {
        setActiveTrack(true);
        setActiveIndex(index);
        setPlayProgram(item.url);
        setBroadcastType(item.broadcast_type);
        setTitle(item.title);
        setDescription(item.description);
        if(item.image) {
            setProgramImage(item.image);
        } else {
            setProgramImage(props.channelImage);
        }
        
        setProgramId(item.programId);
        setTrack(index);
        setChannelImage(props.channelImage);
        setAuthor(item.author);
        // console.log("program url:", item.url);//'#0DBD08';
        setActiveIndex(index);
        // currentTrackRef.current.style.color = '#0DBD08'; 
        // console.log("ref color", currentTrackRef.current.classList);

        // goes in element  ref={i == activeIndex ? currentTrackRef : null}
        clearTrackHighlight(index);
        

    }


    function getTrackDetails(trackList, track) {
        
        let trackNumber = track;
        if(track < trackList.length) {
            trackNumber = track + 1;
            setTrack(trackNumber);
            setPlayProgram(trackList[trackNumber].url);
            setBroadcastType(trackList[track].broadcast_type);
            setTitle(trackList[trackNumber].title);
            setDescription(trackList[trackNumber].description);
            if(trackList[trackNumber].image) {
                setProgramImage(trackList[trackNumber].image);
            } else {
                setProgramImage(props.channelImage);
            }
            
            setProgramId(trackList[trackNumber].programId);
            setTrack(trackNumber);
            setChannelImage(props.channelImage);
            setAuthor(trackList[trackNumber].author);
            // console.log("Track details", trackList, trackNumber);
            clearTrackHighlight(trackNumber);
        } else {
            trackNumber = 0;
            setTrack(0);
            setPlayProgram(trackList[trackNumber].url);
            setBroadcastType(trackList[trackNumber].broadcast_type);
            setTitle(trackList[trackNumber].title);
            if(trackList[trackNumber].image) {
                setProgramImage(trackList[trackNumber].image);
            } else {
                setProgramImage(props.channelImage);
            }
            
            setProgramId(trackList[trackNumber].programId);
            setTrack(trackNumber);
            setChannelImage(props.channelImage);
            setAuthor(trackList[trackNumber].author);
            console.log("Track details", trackList, track);
            
        }
    }

    /*Shuffle*/
    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    } 

    useEffect(() => {
        setProgramsAPI(props.programsAPI);
    },[props.programsAPI]);

    useEffect(() => {
        if(programs) {
            // if(props.randomize == true) {
            //     var orderedResult = shuffle(result);
            // } else {
            //     var orderedResult = result;
            // }
            // setIsLoaded(true);
            // setItems(orderedResult);

            // setPlayProgram(orderedResult[0].url);
            // setBroadcastType(orderedResult[0].broadcast_type);
            // setTitle(orderedResult[0].title);
            // setDescription(orderedResult[0].description);
            // if(orderedResult[0].image) {
            //     setProgramImage(orderedResult[0].image);
            // } else {
            //     setProgramImage(props.channelImage);
            // }
            
            // setProgramId(orderedResult[0].programId);
            // setTrack(0);
            // setChannelImage(props.channelImage);
            // setAuthor(orderedResult[0].author);
            // highlightTrack(0);
        } else {
            setTimeout(() => {
                {programsAPI && fetch(`${programsAPI}?channelID=${props.channelID}`)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(props.randomize == true) {
                            var orderedResult = shuffle(result);
                        } else {
                            var orderedResult = result;
                        }
                        setIsLoaded(true);
                        setItems(orderedResult);

                        setPlayProgram(orderedResult[0].url);
                        setBroadcastType(orderedResult[0].broadcast_type);
                        setTitle(orderedResult[0].title);
                        setDescription(orderedResult[0].description);
                        if(orderedResult[0].image) {
                            setProgramImage(orderedResult[0].image);
                        } else {
                            setProgramImage(props.channelImage);
                        }
                        
                        setProgramId(orderedResult[0].programId);
                        setTrack(0);
                        setChannelImage(props.channelImage);
                        setAuthor(orderedResult[0].author);
                        highlightTrack(0);
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                ).then(() => {
                    setTimeout(() => {
                        clearTrackHighlight(0);
                    },1000);
                    
                })}
            }, 500)
        }
    }, [props.channelID, programsAPI, programs])

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div></div>
    } else {
        return (
            <div>
                <Grid container direction="row" style={{justifyContent:"flex-start", alignItems:"flex-start", backgroundColor: theme[0].media_player_background_color, border: theme[0].page_image_border, boxShadow: theme[0].page_image_shadow}}>
                    <Grid item xs={12} sm={12} md={9} style={{backgroundColor: theme[0].media_player_background_color, backgroundImage: channelBaseUrl + props.channelImage}}>
                        <MediaPlayer getTrackDetails={getTrackDetails} channelID={props.channelID} url={playProgram} broadcast_type={broadcast_type} programImage={programImage} baseUrl={channelBaseUrl} trackList={items} track={track} programId={programId} theme={theme} channelName={props.channelName} channelImage={channelImage} title={title} author={author} description={description} handleSoundBars={handleSoundBars} />
                    </Grid>
                    <Grid className="playlist sideBarWrapper" item xs={12} sm={12} md={3} style={{backgroundColor: `${theme[0].playlist_background_color}`, overflowY: 'auto'}}>
                        {/* <h3 style={{backgroundColor: theme[0].media_player_background_color, fontColor: theme[0].playlist_font_color}}>{props.channelName}</h3> */}
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', margin: '0', padding: '0', alignItems:"flex-start" }}>
                            {props.channelImage && <ListItem style={{padding: '0'}}>
                                <img className="playelistChannelImage" src={channelBaseUrl + props.channelImage} alt={props.channelName} style={{width: '100%'}}/>
                            </ListItem>}
                            <ListItemText primary={props.channelName} style={{backgroundColor: theme[0].media_player_background_color, color: theme[0].playlist_font_color, margin: '0'}} />
                        </List>
                        <List style={{overflowY: 'auto'}}>
                            {items && items.map((item, i) => (
                                <ListItem id={i} className="playlistItems" key={i} style={{alignItems: "flex-start", color:theme[0].playlist_font_color}}>
                                    <ListItemAvatar onClick={() => {setProgramUrl(item, i)}}>
                                        <Avatar alt={item.title} src={props.channelBaseUrl+item.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <div>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    
                                                    className="trackTitle"
                                                    // color= {theme[0].playlist_font_color} 
                                                    
                                                    // className={classes.inline}
                                                    onClick={() => {setProgramUrl(item, i)}}
                                                >
                                                    <span ref={currentTrackRef} className="playlistTitle">{item.title}</span>
                                                </Typography>
                                            </div>
                                        }
                                        secondary={
                                            <div>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    // className={classes.inline}
                                                    color="textPrimary"
                                                    onClick={() => {setProgramUrl(item, i)}}
                                                >
                                                    <span className="playlistDescription" style={{color: `${theme[0].playlist_font_color}`}}>{item.author}</span>
                                                    
                                                </Typography>
                                                {item.description && <Typography component="details" className="playlistDescription" style={{color: `${theme[0].playlist_font_color}`}} dangerouslySetInnerHTML={{__html: item.description}} />}
                                            </div>
                                        }
                                    />
                                    <ListItemIcon style={{alignSelf: 'center'}}>
                                        {/* <SoundBars/> */}
                                    </ListItemIcon>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </div>
        );
    }

}
export default Playlist;