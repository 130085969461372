import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TemporaryDrawer from '../web-components/Drawer';
import Grid from '@mui/material/Grid';
import { ThemeContext } from '../App';
import './appbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { auth } from '../../firebase';
import { Form} from 'react-bootstrap'


export default function DenseAppBar(props) {
  const theme = React.useContext(ThemeContext);
  //const [theme, setTheme] = React.useState(props.theme);
  const [baseUrl, setBaseUrl] = React.useState(props.baseUrl);
  const networkName = props.networkName ? props.networkName : localStorage.getItem("networkName");
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopoverId, setOpenPopoverId] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleLogout = () => {
    auth.signOut();
  }

  const getNetworks = props.getNetworks;
  
  return (
    <Box sx={{ flexGrow: 1 }} style={{position: 'fixed'}} className="appbarHeader">
      <AppBar position="static">
        <Toolbar variant="dense" style={{backgroundColor: theme[0].title_bar_background_color, color: theme[0].title_bar_font_color}}>
          <Grid container>
              <Grid item xs={1}>
                <div edge="start" aria-label="menu">
                  {theme && <TemporaryDrawer sideMenu={props.sideMenu} />}
                </div>
              </Grid>
              <Grid item xs={6} md={10} style={{textAlign: 'center'}}>
                {theme[0].side_menu_logo ? ( <img className="logoProperties" src={baseUrl + theme[0].side_menu_logo} style={{maxWidth: '100%'}} sx={4}/>) : (
                  <span className="mainLogo">{networkName}</span>
                )}
              </Grid>

              <Grid item xs={1}>
                <Button onClick={handleLogout} style={{color: theme[0].title_bar_font_color}}><FontAwesomeIcon icon="sign-out-alt" fontSize="large"/> &nbsp;Logout</Button>
              </Grid>
              {/* <Grid item xs={2}>
                  <BasicModal
                    open={open}
                    onClose={handleClose}
                    dialogType="login"
                  />
              </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
