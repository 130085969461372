import React, { useEffect, useState } from 'react'

export default function useUserData(uid, db) {
    const [userDetails, setUserDetails] = useState([]);

    useEffect(() => {
        db.collection("users").where("uid", "==", uid).onSnapshot((snapshot) => {
          setUserDetails(
            snapshot.docs.map((doc) => ({
              data: doc.data(),
            }))
          );
        });
        
    }, [uid]);

    return [userDetails];
}