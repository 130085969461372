import React, { useEffect, useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap' 
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import useUserData from '../../hooks/usersHook';

const UpdateProfile = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const nameRef = useRef();
    const companyRef = useRef();
    const phoneRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { currentUser, updatePassword, updateEmail, updateName, updateCompany, updatePhone } = useAuth();
    const [userDetails, setUserDetails] = useUserData(currentUser.uid, db);

    function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match');
        }

        const promises = [];
        setLoading(true);
        setError('');

        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value))
        }

        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value));
        }

        if (nameRef.current.value !== currentUser.displayName) {
            promises.push(updateName(nameRef.current.value));
        }

        if (companyRef.currentValue !== "") {
            promises.push(updateCompany(companyRef.current.value))
        }

        if (phoneRef.currentValue !== "") {
            promises.push(updatePhone(phoneRef.current.value))
        }

        promises.push(currentUser.uid);

        Promise.all(promises).then(() => {
            navigate("/profile");
        }).catch(() => {
            setError('Failed to update account.');
        }).finally(() => {
            setLoading(false);
        })
    }
  return (
    <>
        <Card>
            <Card.Body>
                <h2 className='text-center mb-4'>Update Profile</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} defaultValue={currentUser.email} />
                    </Form.Group>
                    <Form.Group id="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" ref={nameRef} defaultValue={currentUser.displayName} />
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} placeholder="Keep blank to leave the same." />
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control type="password" ref={passwordConfirmRef} requiredplaceholder="Keep blank to leave the same." />
                    </Form.Group>
                    <Form.Group id="company">
                        <Form.Label>Company</Form.Label>
                        <Form.Control type="text" ref={companyRef} defaultValue={userDetails[0] ? userDetails[0].data.company : ''} />
                    </Form.Group>
                    <Form.Group id="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" ref={phoneRef} defaultValue={userDetails[0] ? userDetails[0].data.phone : ''} />
                    </Form.Group>
                    <Button type="submit" className="w-100 mt-3">Update</Button>
                </Form>
            </Card.Body>
        </Card>
        < div className="w-100 text-center mt-2">
            <Link to="/">Cancel</Link>
        </div>
    </>
  )
}
export default UpdateProfile;
