import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, allergies } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link, Router, NavLink } from 'react-router-dom';
import { ThemeContext } from '../App';

export default function TemporaryDrawer(props) {
    const theme = React.useContext(ThemeContext);
    const [sideMenu, setSideMenu] = React.useState(props.sideMenu);
    const userSecret = 'user-secret';
    // console.log("side menu from props", sideMenu);
    const availableIcons = {
        secretUser: 'secret-user',
        allergies: 'allergies',
      }
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const icon = "Delete";

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const anchor = "left";

  const list = (anchor) => (
      <>
        <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        style={{backgroundColor: theme[0].side_menu_background_color, height: '100vh'}}
        >
            <List style={{padding: '12px'}}>
                {sideMenu.map((menu, index) => (
                  <>
                    <ListItem key={Math.random()} disablePadding className="sideMenuItems" style={{padding: '12px 0'}}>
                        <ListItemText key={Math.random()} className="listItemText">
                            <FontAwesomeIcon key={Math.random()} style={{color: theme[0].side_menu_font_color}} icon={menu.icon} fontSize={20} /> <Link to={menu.url} style={{color: theme[0].side_menu_font_color, textDecoration: 'none'}}>{menu.title}</Link>
                        </ListItemText>
                    </ListItem>
                    <Divider key={Math.random()} style={{borderColor: `${theme[0].side_menu_font_color}`, borderWidth: '1px'}} />
                  </>
                ))}
            </List>
            
        </Box>
    </>
  );

  return (
    <div>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><FontAwesomeIcon style={{color: theme[0].title_bar_font_color}} icon="bars" fontSize="large"/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      {/* ))} */}
    </div>
  );
}
