import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InfoIcon from '@mui/icons-material/Info';

import LoginIcon from '@mui/icons-material/Login';
import LoginModalContent from './LoginModalContent';
import TrackDetailsModalContent from './TrackDetailsModalContent';

import { ThemeContext } from '../App';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



function BasicModal(props) {
  const theme = React.useContext(ThemeContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
        {props.dialogType == "login" ? ( 
          <LoginModalContent
            handleOpen={handleOpen}
            handleClose={handleClose}
            style={style}
            open={open}
          />
        ) : (
          <TrackDetailsModalContent
            title={props.title}
            author={props.author}
            description={props.description}
            handleOpen={handleOpen}
            handleClose={handleClose}
            style={style}
            open={open}
          />
        )}
    </div>
  );
}

export default BasicModal;
