import React, { useContext, useState } from 'react'
import Banners from '../banners/Banners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './dashboard.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../App';

export default function Dashboard(props) {
  const bannersAPI = props.bannersAPI;
  const sideMenu = useState(props.sideMenu);
  const dashboardMenu = useState(props.dashboardMenu);
  // console.log("dashboard menu", dashboardMenu);
  const theme = useContext(ThemeContext);
  // console.log(sideMenu);
  return (
    <div>
      <Banners bannersAPI={bannersAPI} />
      <Container>
        <Row className="dashboardItems" xs={2} md={4} lg={80}>
          {dashboardMenu && dashboardMenu[0].map((menu, i) => (
            <Link key={Math.random()} className="dashboardItem" to={menu.url} style={{color: theme[0].dashboard_border_color, textAlign: 'center', backgroundColor: theme[0].dashboard_background_color, borderColor: theme[0].dashboard_border_color}}>
              <FontAwesomeIcon icon={menu.icon} fontSize={60} />
              <div>{menu.title}</div>
            </Link>
          ))}
          
          {/* <Link className="dashboardItem" to="/broadcast" style={{color: '#fff', textAlign: 'center'}}>
            <FontAwesomeIcon icon="archway" fontSize={70} />
            <div>Broadcast</div>
          </Link>
          <div className="dashboardItem" style={{color: '#fff', textAlign: 'center'}}>
            <Home sx={{ color: '#fff', fontSize: 80}} />
            <div>This is a really really long label</div>
          </div>
          <div className="dashboardItem" style={{color: '#fff', textAlign: 'center'}}>
            <Home sx={{ color: '#fff', fontSize: 80}} />
            <div>Home</div>
          </div>
          <div className="dashboardItem" style={{color: '#fff', textAlign: 'center'}}>
            <Home sx={{ color: '#fff', fontSize: 80}} />
            <div>Home</div>
          </div>
          <div className="dashboardItem" style={{color: '#fff', textAlign: 'center'}}>
            <Home sx={{ color: '#fff', fontSize: 80}} />
            <div>Home</div>
          </div> */}
        </Row>
        <Row>
          &nbsp;
        </Row>
      </Container>
    </div>
  )
}