import React from 'react';
import LoginIcon from '@mui/icons-material/Login';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';

function LoginModalContent(props) {
const [loggedIn, setLoggedIn] = React.useState(localStorage.getItem("loggedIn"));

const logOut=() => {
    setTimeout(() => {
        fetch(`https://shelcaster.tv/auth?action=post&module=user&resource=logout`)
        .then(result => result.json())
        .then(result => {
            localStorage.setItem("session_id", "");
            localStorage.setItem("loggedIn", false);
            localStorage.setItem("user_id", "");
            setLoggedIn(false);
            console.log("logout response", result.session_id);
        },
        (error) => {
            console.log("Logout failed", error.message);
        })
    
    }, 400);
}

    return (
        <React.Fragment>

                {!loggedIn ? (
                <React.Fragment>
                    <Button onClick={props.handleOpen}><LoginIcon /></Button>
                    <Modal
                        open={props.open}
                        onClose={props.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{backgroundColor: 'rgba(0,0,0,.6)', border: '2px solid #fff'}}
                    >
                        <Box sx={props.style} style={{backgroundColor: 'rgba(0,0,0,.6)', border: '1px solid #fff'}}>

                        </Box>
                    </Modal>
                </React.Fragment>
                ) : (
                    <Button onClick={logOut}> <LogoutIcon /></Button>
                )}

        </React.Fragment>
    )
}
export default LoginModalContent;