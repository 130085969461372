import * as React from 'react';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';
import './AudioControls.scss';
import { Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';
import { ThemeContext } from '../App';

function AudioControls({isPlaying, onPlayPauseClick, played, duration, handleRewind, handleFastForward, muted, onMute, offMute, progress, handleProgressChange, handleChange, value, handleNextTrack, handlePreviousTrack}) {
    const [progressValue, setProgressValue] = React.useState(0);
    const [position, setPosition] = React.useState(0);
    const theme = React.useContext(ThemeContext);

    return (
        <div className="audio-controls">
            {isPlaying ? (
            <IconButton onClick={() => onPlayPauseClick(false)}><PauseOutlinedIcon style={{color: theme[0].media_player_font_color}}/></IconButton>
            ) : (<IconButton onClick={() => onPlayPauseClick(true)}><PlayArrowOutlinedIcon style={{color: theme[0].media_player_font_color}}/></IconButton>)} 
            <IconButton
                onClick={handleRewind}
            >
                <Replay10Icon style={{color: theme[0].media_player_font_color}} fontSize="inherit" />
            </IconButton>
            <IconButton
                onClick={handleFastForward}
            >
                <Forward10Icon style={{color: theme[0].media_player_font_color}} fontSize="inherit" />
            </IconButton>
            <IconButton
                onClick={handlePreviousTrack}
            >
                <FastRewindOutlinedIcon style={{color: theme[0].media_player_font_color}}/>
            </IconButton> 
            <Box width={300}>
                <Slider
                    size="small"
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    // defaultValue={0}
                    value={played}
                    step={1}
                    max={duration.duration}
                    // onChange={(_, value) => setPosition(value)}
                    onChange={handleProgressChange}
                    style={{marginTop:'9px', color:'#fff'}}
                />
            </Box>
            <IconButton
                onClick={handleNextTrack}
            >
                <FastForwardOutlinedIcon style={{color: theme[0].media_player_font_color}}/>
            </IconButton>
            <Box sx={{ width: 200 }}>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    {muted ? (
                    <IconButton onClick={onMute}>
                        <VolumeOffIcon style={{color: theme[0].media_player_font_color}}/>
                    </IconButton>) : (
                    <IconButton onClick={offMute}>
                        <VolumeDown style={{color: theme[0].media_player_font_color}}/>
                    </IconButton>)}

                    <Slider 
                        className="slider" 
                        aria-label="Volume" 
                        size="small" 
                        step={.1} 
                        max={1} 
                        value={value} 
                        onChange={handleChange} 
                        style={{marginTop:'4px', color: theme[0].media_player_font_color}} 
                    />
                    <IconButton onClick={onMute}>
                        <VolumeUp style={{color: theme[0].media_player_font_color}} />
                    </IconButton>
                </Stack>
            </Box>
        </div>
    )
}

export default AudioControls;