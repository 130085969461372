import React, { useEffect, createContext } from "react";
import { Container } from "react-bootstrap";
import { AuthProvider, useAuth} from "../contexts/AuthContext";
import Signup from "./authentication/Signup";
import {BrowserRouter as Router, Routes, Route, useParams} from "react-router-dom";
import Profile from "./Profile/Profile";
import Login from "./authentication/Login";
import PrivateRoute from './authentication/PrivateRoute';
import ForgotPassword from "./authentication/ForgotPassword";
import UpdateProfile from "./Profile/UpdateProfile";
import Dashboard from "./Dashboard/Dashboard";
import Broadcast from './broadcast/Broadcast';
import '../styles/global.scss';
import Banners from "./banners/Banners";
import DenseAppBar from "./topHeaderBar/Appbar";
import { ContentCutOutlined } from "@mui/icons-material";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faFontAwesome } from '@fortawesome/free-solid-svg-icons'
import UploadForm from "./admin/storage/UploadForm";

library.add(fas, faFontAwesome)

export const ThemeContext = createContext(null);

function App() {
  const [sideMenuAPI, setSideMenuAPI] = React.useState(null);
  const [dashboardMenuAPI, setDashboardMenuAPI] = React.useState(null);
  const [themesAPI, setThemesAPI] = React.useState(null);
  const [bannersAPI, setBannersAPI] = React.useState(null);
  const [articlesAPI, setArticlesAPI] = React.useState(null);
  const [externalNetworksAPI, setExternalNetworksAPI] = React.useState(null);
  const [channelsAPI, setChannelsAPI] = React.useState(null);
  const [singleChannelAPI, setSingleChannelAPI] = React.useState(null);
  const [programsAPI, setProgramsAPI] = React.useState(null);

  const [articles, setArticles] = React.useState(null);
  const [channelCategories, setChannelCategories] = React.useState(null);
  const [sideMenu, setSideMenu] = React.useState(null);
  const [dashboardMenu, setDashboardMenu] = React.useState(null);
  const [theme, setTheme] = React.useState(null);
  const [banners, setBanners] = React.useState(null);
  const [baseUrl, setBaseUrl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showBanner, setShowBanner] = React.useState(true);
  const [networkName, setNetworkName] = React.useState('');
  const [broadcastAPI, setBroadcastAPI] = React.useState(null);

  var hostname = window.location.hostname;
  hostname = hostname.split(".");
  hostname = hostname[0];
  localStorage.setItem("clientId", hostname);
  localStorage.setItem("shelcasterUrl", window.location.href);
  const [clientID, setClientID] = React.useState(hostname? hostname : 'shelcaster-tv');

  function removeBanner() {
      setShowBanner(false)
  }

  // const handleSearch = (e) => {
  //   if(e.target.value.length > 2) {
  //     console.log("search", e.target.value);
  //   }
    
  // }

  //API to call host (primary) network

  React.useEffect(() => {
    async function getNetworks() {
        const response = await fetch(`https://dmb6bpx75m.execute-api.us-east-1.amazonaws.com/networks?clientID=${clientID}`);
        const networks = await response.json();
        localStorage.setItem("clientID", clientID);
        
        // if(networks.length == 0){
        //     window.location.hostname = "shelcaster-tv.shelcaster.com";
        // }
        return networks;
    }
    getNetworks().then(networks => {
        setSideMenuAPI(networks[0].sidemenu_api);
        setDashboardMenuAPI(networks[0].dashboard_menu_api);
        setThemesAPI(networks[0].themes_api);
        setBannersAPI(networks[0].banners_api);
        setArticlesAPI(networks[0].articles_api);
        setExternalNetworksAPI(networks[0].external_networks_api);
        setBaseUrl(networks[0].base_url);
        setBroadcastAPI(networks[0].broadcast_api);
        setChannelsAPI(networks[0].channels_api);
        setSingleChannelAPI(networks[0].channel_single_api);
        setProgramsAPI(networks[0].programs_api);

        localStorage.setItem("baseUrl", networks[0].base_url);
    },
    (error) => {
        console.log("get networks", error.message);
    });

  },[clientID, bannersAPI])

  // API to call list of external (shared) networks
  const categoryArray = [];
  const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
  };

  React.useEffect(() => {
    async function getExternalNetworks() {
        const response = await fetch(externalNetworksAPI);
        const extNetworks = await response.json();
        return extNetworks;
    }

    getExternalNetworks().then(extNetworks => {
        extNetworks.map((network, i) => {
            async function getCategories() {
                const res = await fetch(network.channel_categories_api);
                const data = await res.json();
                return data;
            }

            getCategories().then(data => {
                // console.log("categories data");
                data.map((channelSet, j) => {
                    fetch(`${network.channels_api}?categoryID=${channelSet.id}`)
                    .then(resp => resp.json())
                    .then(resp => {
                        channelSet['channels'] = resp;
                        channelSet['programAPI'] = network.programs_api;
                        channelSet['networkID'] = network.id;
                        channelSet['ordering'] = network.ordering;
                        categoryArray.push(channelSet);
                    })
                })
            },
            (error) => {
                console.log(error.message);
            })
        })
    })
    .then(() => {
        setTimeout(() => {

            categoryArray.sort(function(a, b){
                let x = a.title.toLowerCase();
                let y = b.title.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
            });
              
            let categoryGrouped = groupBy(categoryArray, 'ordering');
            var categoryArrayGrouped = Object.keys(categoryGrouped).map(function(key) {
                return [Number(key), categoryGrouped[key]];
            });
            setChannelCategories(categoryArrayGrouped);
            setIsLoading(false);
        },6000);
        
    },(error) => {
        console.log("external networks", error.message);
    });
  },[externalNetworksAPI]);

  React.useEffect(() => {
    fetch(`${articlesAPI}?articleCategory=dashboard`)
    .then(result => result.json())
    .then(result => {
        if(result) {
          setArticles(result);
        }
    },
    (error) => {
        console.log(error.message);
    })
  },[articlesAPI])

  React.useEffect(() => {
    fetch(sideMenuAPI)
    .then(result => result.json())
    .then(result => {
        setSideMenu(result);
        // console.log("side menu in app", result)
    })
  },[sideMenuAPI])

  React.useEffect(() => {
    fetch(dashboardMenuAPI)
    .then(result => result.json())
    .then(result => {
        setDashboardMenu(result);
    })
  },[dashboardMenuAPI])

  React.useEffect(() => {
    fetch(bannersAPI)
    .then(result => result.json())
    .then(result => {
        setBanners(result);
    },
    (error) => {
        console.log(error.message);
    })
  }, [bannersAPI])

  React.useEffect(() => {
    fetch(themesAPI)
    .then(result => result.json())
    .then(result => {
        setTheme(result);
        localStorage.setItem("theme", result);
    },
    (error) => {
        console.log(error.message);
    })
  },[themesAPI])

  return (
    <ThemeContext.Provider value={theme}>
    <div style={{backgroundImage: `url(https://shelcaster.tv/images/background1.jpg)`, backgroundSize: 'cover'}}>
      {isLoading ? (
        <div className="pulse"><img style={{maxWidth: '80%', margin: '100px auto'}} src="https://shelcaster.tv/images/sc-logo-clear.png" alt="Shelcaster"/></div>
      ) : (
        <Router>
      
        {<DenseAppBar sideMenu={sideMenu} baseUrl={baseUrl} networkName={networkName} channelCategories={channelCategories}/>}
        <Container fluid className="d-flex align-items-center justify-content-center g-0 backgroundColorDiv" style={{ minHeight: "100vh", height: '100%', paddingTop: '70px', backgroundImage:
    `${theme[0].page_background_gradient != '' ? theme[0].page_background_gradient : 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0))'}, url(${theme[0].page_background_image != '' ? baseUrl + theme[0].page_background_image : ''})`, backgroundColor: `${theme[0].page_background_color != 'none' ? theme[0].page_background_color : 'none'}`, backgroundSize: theme[0].page_background_image_size, backgroundRepeat: theme[0].page_background_image_repeat}}>
        
          <div className="w-100" style={{alignSelf: 'start'}}>
            
              <AuthProvider>
                <Routes>
                  <Route exact path="/" element={<PrivateRoute/>}>
                    <Route exact path="/" element={<Dashboard baseUrl={baseUrl} bannersAPI={bannersAPI} dashboardMenu={dashboardMenu} sideMenu={sideMenu}/>}/>
                  </Route>
                  <Route path="/dashboard" element={<PrivateRoute/>}>
                    <Route exact path="/dashboard" element={<Dashboard baseUrl={baseUrl} bannersAPI={bannersAPI} sideMenu={sideMenu} dashboardMenu={dashboardMenu}/>}/>
                  </Route>
                  {/* <Route exact path="/" element={<PrivateRoute/>}>
                    <Route exact path="/" element={<Broadcast channelCategories={channelCategories} baseUrl={baseUrl} onChannelClick={removeBanner} theme={theme}/>}/>
                  </Route> */}
                  <Route exact path="/profile" element={<PrivateRoute/>}>
                    <Route exact path="/profile" element={<Profile/>}/>
                  </Route>
                  {/* <Route exact path="/directory" element={<PrivateRoute/>}>
                    <Route exact path="/directory" element={<UserList/>}/>
                  </Route> */}
                  <Route exact path="/update-profile" element={<PrivateRoute/>}>
                    <Route exact path="/update-profile" element={<UpdateProfile/>}/>
                  </Route>
                  <Route exact path="/file-upload" element={<PrivateRoute/>}>
                    <Route exact path="/file-upload" element={<UploadForm/>}/>
                  </Route>
                  <Route path="/broadcast" element={<PrivateRoute/>}>
                    <Route path="/broadcast/:channelId/:programId" element={<Broadcast programsAPI={programsAPI} channelCategories={channelCategories} singleChannelAPI={singleChannelAPI} baseUrl={baseUrl} onChannelClick={removeBanner} />}/>
                    <Route path="/broadcast/:channelId" element={<Broadcast programsAPI={programsAPI} channelCategories={channelCategories} singleChannelAPI={singleChannelAPI} baseUrl={baseUrl} onChannelClick={removeBanner} />}/>
                    <Route exact path="/broadcast" element={<Broadcast channelCategories={channelCategories} baseUrl={baseUrl} onChannelClick={removeBanner} />}/>
                  </Route>
                  <Route path="/signup" element={<Signup />}/>
                  <Route path="/login" element={<Login />}/>
                  <Route path="/forgot-password" element={<ForgotPassword />}/>
                </Routes>
              </AuthProvider>

          </div>
        </Container>
      
      </Router>
      )}
    </div>
    </ThemeContext.Provider>
  );
}

export default App;
