import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Container } from 'react-bootstrap' 
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    
    const emailRef = useRef();
    const passwordRef = useRef();

    const { login } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [uid, setUid] = useState('');
    var shelcasterUrl = localStorage.getItem("shelcasterUrl");

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value)
            .then(() => {
                if(shelcasterUrl !== '') {
                    window.location.replace(shelcasterUrl);
                } else {
                    navigate("/")
                }
            })
            // console.log("uid", useAuth().uid);
            
        } catch {
            setError('Failed to sign in');
        }
        setLoading(false);
    }
  return (
    <>
        <Container className="align-items-center justify-content-center g-0" style={{ maxWidth: "400px"}}>
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Log In</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Button type="submit" className="w-100 mt-3">Log In</Button>
                    </Form>
                    < div className="w-100 text-center mt-3">
                        <Link to="/forgot-password">Forgot password?</Link>
                    </div>
                    < div className="w-100 text-center mt-2">
                Need an account? <Link to="/signup">Sign Up</Link>
            </div>
                </Card.Body>
            </Card>
            
        </Container>
    </>
  )
}
export default Login;
