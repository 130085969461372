import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap' 
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const emailRef = useRef();

    const { resetPassword } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage("Check your inbox for further instructions.");
        } catch {
            setError('Failed to reset password');
        }

        setLoading(false);
    }
  return (
    <>
        <div style={{ maxWidth: "400px"}}>
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Reset Password</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Button type="submit" className="w-100 mt-3">Reset Password</Button>
                    </Form>
                    < div className="w-100 text-center mt-3">
                        <Link to="/login">Login?</Link>
                    </div>
                </Card.Body>
            </Card>
            < div className="w-100 text-center mt-2">
                Need an account? <Link to="/signup">Sign Up</Link>
            </div>
        </div>
    </>
  )
}
export default ForgotPassword;
